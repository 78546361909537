/* Chat.css */

.chat-container {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.chat-title {
  color: var(--primary-red);
}

.chat-card {
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.chat-card-header {
  background-color: var(--primary-red);
  color: var(--white);
  border-bottom: 2px solid var(--primary-red);
  border-radius: 10px 10px 0 0;
}

.chat-card-footer {
  background-color: var(--light-grey);
  border-top: 1px solid var(--light-grey);
  border-radius: 0 0 10px 10px;
}

.message-form .btn-danger {
  background-color: var(--primary-red);
  border: none;
}

.message-form .btn-danger:hover {
  background-color: var(--dark-red);
}

.unread-divider {
  text-align: center;
  color: var(--primary-red);
  margin: 10px 0;
}
