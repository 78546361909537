.notice-details-card {
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
  }
  
  .notice-details-card-header {
    background-color: var(--primary-red);
    color: var(--white);
    border-bottom: 2px solid var(--primary-red);
    border-radius: 10px 10px 0 0;
  }
  
  .notice-details-card-body {
    padding: 1rem;
  }
  