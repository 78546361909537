/* QnACreateQuestion.css */
.create-question-container {
    background-color: var(--white);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .create-question-container .btn-primary {
    background-color: var(--primary-red);
    border: none;
  }
  
  .create-question-container .btn-primary:hover {
    background-color: var(--dark-red);
  }
  