/* Login.css */
.login-container {
    background-color: var(--light-grey);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 0;
  }
  
  .login-card {
    width: 30rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 2rem;
    background-color: var(--white);
  }
  
  .login-logo {
    width: 300px;
    height: auto;
    margin-bottom: 1rem;
  }
  
  .card-title {
    color: var(--primary-red);
  }
  
  .btn-danger {
    background-color: var(--primary-red);
    border: none;
  }
  
  .btn-danger:hover {
    background-color: var(--dark-red);
  }
  
  .form-label {
    color: var(--dark-grey);
  }
  
  .alert-danger {
    background-color: var(--primary-red);
    color: var(--white);
    border: none;
  }
  