.notice-create-container {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .quill-container .ql-editor {
    background-color: white;  /* White background */
    min-height: 200px;  /* Ensure a minimum height */
    padding: 10px;  /* Add some padding */
    border-radius: 4px;  /* Rounded corners */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);  /* Add a subtle shadow */
}