/* Dashboard.css */

html, body, .dashboard-container {
    height: 100%;
    margin: 0;
    color: #333; /* Ensure text is visible */
  }
  
  .custom-navbar {
    background-color: var(--primary-red);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .custom-navbar .navbar-brand {
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  
  .custom-navbar .navbar-nav .nav-link {
    color: white;
  }
  
  .custom-navbar .navbar-nav .nav-link:hover, 
  .custom-navbar .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: var(--light-grey);
    font-weight: bold;
  }
  
  .custom-navbar .profile-picture {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }
  
  .content {
    /* flex: 1 0 auto; */
    height: calc(100vh - 120px);
  }
  
  .footer {
    background-color: var(--primary-red);
    color: #0a0a0a !important;
    padding: 10px 0;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  
  .footer a {
    color: #0a0a0a !important;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  