/* Polls.css */
.polls-container {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.polls-title {
  color: var(--primary-red);
}

.polls-container .btn-danger {
  background-color: var(--primary-red);
  border: none;
}

.polls-container .btn-danger:hover {
  background-color: var(--dark-red);
}