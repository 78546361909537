body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-red: #d32f2f;
  --dark-red: #b71c1c;
  --light-grey: #f3f4f6;
  --white: #ffffff;
  --dark-grey: #424242;
}

body {
  background-color: var(--light-grey);
  margin: 0;
  padding: 0;
}

.full-bg {
  background-color: var(--light-grey);
  width: 100vw;
  min-height: calc(100vh);
  display: flex;
  justify-content: center;
  align-items: flex-start;  
  padding-top: 56px;
  padding-bottom: 56px;
}