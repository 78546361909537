/* QnAList.css */
.unit-list {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.unit-list-item {
  cursor: pointer;
  border-bottom: 1px solid var(--light-grey);
}

.unit-list-item:last-child {
  border-bottom: none;
}