/* Message.css */
.message-wrapper {
    display: flex;
    margin-bottom: 10px;
  }
  
  .message-wrapper.left {
    justify-content: flex-start;
  }
  
  .message-wrapper.right {
    justify-content: flex-end;
  }
  
  .message {
    padding: 10px;
    border-radius: 20px;
    max-width: 60%;
    word-wrap: break-word;
  }
  
  .message.left {
    background-color: #e0e0e0;
  }
  
  .message.right {
    background-color: #d32f2f;
    color: white;
  }
  
  .message.text-start {
    background-color: #e0e0e0;
    padding: 10px;
    border-radius: 10px;
  }
  
  .message.text-end {
    background-color: #d32f2f;
    padding: 10px;
    border-radius: 10px;
    color: white;
  }
  