.contact-list {
    margin-top: 20px;
}

.contact-list-item {
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.contact-list-item:hover {
    background-color: #f8f9fa;
}
