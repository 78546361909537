.user-management-container {
    max-width: 800px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .user-management-title {
    color: var(--primary-red);
  }
  
  .user-management-card {
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
  
  .user-management-card-header {
    background-color: var(--primary-red);
    color: var(--white);
    border-bottom: 2px solid var(--primary-red);
    border-radius: 10px 10px 0 0;
  }
  
  .user-management-card-footer {
    background-color: var(--light-grey);
    border-top: 1px solid var(--light-grey);
    border-radius: 0 0 10px 10px;
  }
  
  .message-form .btn-primary {
    background-color: var(--primary-red);
    border: none;
  }
  
  .message-form .btn-primary:hover {
    background-color: var(--dark-red);
  }
  