/* PollCreateForm.css */
.poll-create-container {
  background-color: var(--white);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.poll-create-container .form-group,
.poll-create-container .form-control,
.poll-create-container .btn {
  margin-bottom: 15px;
}

.poll-create-container .btn-danger {
  background-color: var(--primary-red);
  border: none;
  width: 100%;
}

.poll-create-container .btn-danger:hover {
  background-color: var(--dark-red);
}

.poll-create-container .btn-secondary {
  margin-bottom: 15px;
  width: 100%;
}

.poll-create-container .form-check-label {
  margin-left: 10px;
}

.accordion .card {
  margin-bottom: 10px;
}

.accordion .card-header {
  cursor: pointer;
}

.accordion .card-body {
  padding: 15px;
}
