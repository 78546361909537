/* PollsList.css */
.polls-list-container {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}

.polls-list {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.polls-list-item {
  cursor: pointer;
  border-bottom: 1px solid var(--light-grey);
}

.polls-list-item:last-child {
  border-bottom: none;
}

.polls-list-item.inactive-poll {
  cursor: not-allowed;
  background-color: var(--light-grey);
}

.inactive-poll-text {
  color: rgba(0, 0, 0, 0.5);
}
