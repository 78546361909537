/* QnAChat.css */
.qna-chat-card {
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-height: calc(100vh - 250px);

  }
  
  .card-header.qna-chat-card-header {
    background-color: var(--primary-red);
    color: var(--white);
    border-bottom: 2px solid var(--primary-red);
    border-radius: 10px 10px 0 0;
  }
  
  .qna-chat-card-body {
    max-height: calc(100vh - 350px);
    padding: 1rem;
    background-color: white;
    border: 1px solid var(--light-grey);
    border-radius: 5px;
    overflow-y: auto;
  }
  
  .qna-chat-card-footer {
    background-color: var(--light-grey);
    border-top: 1px solid var(--light-grey);
    border-radius: 0 0 10px 10px;
  }
  